import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router"

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useBusinessList() {
  // Use toast
  const toast = useToast();

  const refBusinessesList = ref(null);

  const isLoading = ref(false)

  // Table Handlers
  const tableColumns = [
    { key: "name" },
    { key: "registration_number" },
    { key: "kra_pin" },
    { key: "category" },
    { key: "actions" },
  ];
  const per_page = ref(5);
  const totalBusinesses = ref(0);
  const current_page = ref(1);
  const per_pageOptions = [5, 10, 15, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const businessData = ref(null)

  const counties = [
    'National', 'Nairobi', 'Kiambu', 'Nakuru', 'Kisumu', 'Mombasa', 'Machakos', 'Kajiado'
  ]

  const dataMeta = computed(() => {
    const localItemsCount = refBusinessesList.value
      ? refBusinessesList.value.localItems.length
      : 0;
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalBusinesses.value,
    };
  });

  const refetchData = () => {
    refBusinessesList.value.refresh();
  };

  watch([current_page, per_page, searchQuery], () => {
    refetchData();
  });

  const fetchBusinesses = (ctx, callback) => {
    store
      .dispatch("business/fetchBusinesses", {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then((response) => {
        const { data: businesses, total } = response.data.data;
        callback(businesses);
        totalBusinesses.value = total;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching businesses",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchBusinesses,
    tableColumns,
    per_page,
    current_page,
    totalBusinesses,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBusinessesList,
    refetchData,

    businessData,
    counties,

    isLoading,
  };
}
