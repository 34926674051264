<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            lg="6"
            md="6"
            sm="12"
            class="mb-1 mb-md-0"
          >
            <b-row>
              <b-col lg="6" md="6" sm="12">
                <label>Show</label>
                <v-select
                  v-model="per_page"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="per_pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-button
                  class="table-header-btn"
                  variant="primary"
                  :to="{ name: 'license-new' }"
                >
                  Apply for new License
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <!-- Search -->
          <b-col 
            lg="6"
            md="6"
            sm="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refBusinessesList"
        class="position-relative"
        :items="fetchBusinesses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Tariff -->
        <template #cell(category)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              shortenTariff(data.item.category.name)
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button :to="{ name: 'entity-view', params: { id: data.item.id }}" variant="primary" size="sm" class="ml-1">View</b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalBusinesses"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BLink,
  BFormInput,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect,
  BModal,
  BCardText,
  BCardBody, 
  BTableLite,
  VBToggle,
  BTooltip,
  BSpinner,
} from "bootstrap-vue";
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useBusinessList from "./useBusinessList";
import businessStoreModule from "./businessStoreModule";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect,
    BModal,
    BCardText,
    BCardBody, 
    BTableLite,
    BTooltip,
    Logo,
    BSpinner,

    vSelect,

    ValidationProvider,
    ValidationObserver,
    required,

    Ripple,
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = "business";
    // Use toast
    const toast = useToast();

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME))
      store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, businessStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME))
        store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME);
    });

    const isAddNewArticleSidebarActive = ref(false);

    const shortenTariff = (tariff) => {
      if(tariff.length > 20) {
        return tariff.substr(0, 20) + '...'
      } else {
        return tariff
      }
    }

    const {
      fetchBusinesses,
      tableColumns,
      per_page,
      current_page,
      totalBusinesses,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBusinessesList,
      refetchData,
    } = useBusinessList();

    return {
      // Sidebar
      isAddNewArticleSidebarActive,

      fetchBusinesses,
      tableColumns,
      per_page,
      current_page,
      totalBusinesses,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBusinessesList,
      refetchData,

      shortenTariff,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.table-header-btn {
  margin-right: 2px;
}
@media only screen and (max-width: 992px) {
  .table-header-btn {
    margin-top: 3px;
    margin-right: 0;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
